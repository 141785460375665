@import "https://fonticons.thabangz.com/assets/css/brands.css";
@import "https://fonticons.thabangz.com/assets/css/business-services.css";
@import "https://fonticons.thabangz.com/assets/css/cleaning-services.css";
@import "https://fonticons.thabangz.com/assets/css/digital-assets.css";
@import "https://fonticons.thabangz.com/assets/css/engineering-services.css";
@import "https://fonticons.thabangz.com/assets/css/office-work.css";
@import "https://fonticons.thabangz.com/assets/css/reja-code.css";
@import "https://fonticons.thabangz.com/assets/css/service-providers.css";
@import "https://fonticons.thabangz.com/assets/css/smart-home.css";
@import "https://fonticons.thabangz.com/assets/css/software-development.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

i {
  font-size: 200px;
  font-style: normal;
}
