@import url(https://fonticons.thabangz.com/assets/css/brands.css);
@import url(https://fonticons.thabangz.com/assets/css/business-services.css);
@import url(https://fonticons.thabangz.com/assets/css/cleaning-services.css);
@import url(https://fonticons.thabangz.com/assets/css/digital-assets.css);
@import url(https://fonticons.thabangz.com/assets/css/engineering-services.css);
@import url(https://fonticons.thabangz.com/assets/css/office-work.css);
@import url(https://fonticons.thabangz.com/assets/css/reja-code.css);
@import url(https://fonticons.thabangz.com/assets/css/service-providers.css);
@import url(https://fonticons.thabangz.com/assets/css/smart-home.css);
@import url(https://fonticons.thabangz.com/assets/css/software-development.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

i {
  font-size: 200px;
  font-style: normal;
}

.App {
  min-height: 400px;
  margin-bottom: 100px;
  clear: both;
}

img {
  width: 100%;
}

.landing {
  position: relative;
  background: url(/static/media/showcase.d6805fb4.jpeg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  margin-top: -24px;
  margin-bottom: -50px;
}

.landing-inner {
  padding-top: 80px;
}

.dark-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rejacode-logo {
  height: 150px;
  width: 230px;
}

.card-form {
  opacity: 0.9;
}

.card-inner {
  margin-left: 4rem;
}

.latest-profiles-img {
  width: 40px;
  height: 40px;
}

.form-control::placeholder {
  color: #bbb !important;
}

.article-sm {
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0 0 1px #ccc;
}

.article-sm .content {
  padding: 15px;
}
.article-sm .author {
  font-size: 11px;
  color: #737373;
  padding: 25px 30px 20px;
}
.article-sm .article-sm-img-content {
  height: 196px;
  position: relative;
}
.article-sm .article-sm-img-content img {
  position: absolute;
}
.article-sm .article-sm-title {
  display: table-cell;
  vertical-align: bottom;
  z-index: 2;
  position: relative;
}
.article-sm .article-sm-title b {
  background-color: rgba(51, 51, 51, 0.58);
  display: inline-block;
  margin-bottom: 5px;
  color: #fff;
  padding: 10px 15px;
  margin-top: 5px;
}

.article {
  background-color: #fff;
  box-shadow: 0 0 1px #ccc;
}

.article .content {
  padding: 15px;
}

.article .author {
  font-size: 20px;
  color: #737373;
  padding: 25px 30px 20px;
}

.article .article-title {
  display: table-cell;
  vertical-align: bottom;
  font-size: 30px;
  position: relative;
}

.article .article-title b {
  background-color: rgba(51, 51, 51, 0.58);
  display: inline-block;
  color: #fff;
  padding: 10px 15px;
}

.discussions-section {
  background: #fff;
}

.discussion-area {
  background: none repeat scroll 0 0 #fff;
  border: medium none;
  border-radius: 4px 4px 0 0;
  color: #777777;
  float: left;
  font-family: Lato;
  font-size: 18px;
  height: 85px;
  letter-spacing: 0.3px;
  padding: 10px 20px;
  width: 100%;
  resize: vertical;
  outline: none;
  border: 1px solid #f2f2f2;
}

.discussion-btn {
  background: #4caf50;
  margin: 5px 0;
  padding: 6px 15px;
  color: #fff;
  letter-spacing: 1.5px;
  outline: none;
  border-radius: 4px;
  box-shadow: none;
}

.discussion-btn:hover,
.discussion-btn:focus {
  background: #2e7d32;
  outline: none;
  border-radius: 4px;
  box-shadow: none;
}

.discussion-box-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 5px 0px;
}

.discussion-box {
  display: flex;
  width: 100%;
}

.discussion-box a {
  color: #242475;
}

.discussioner-image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.discussion-content {
  display: flex;
  flex-direction: column;
  background: #f2f3f5;
  margin-left: 5px;
  padding: 4px 20px;
  border-radius: 10px;
}

.discussioner-head {
  display: block;
}

.discussioner-head .discussioner-name {
  font-size: 0.9rem;
  font-weight: 600;
}

.discussion-date {
  font-size: 0.7rem;
}

.discussion-date i {
  margin: 0 5px 0 10px;
}

.discussion-body {
  padding: 0 0 0 5px;
  display: flex;
  font-size: 1.1rem;
  font-weight: 400;
}

.discussion-footer {
  font-size: 1rem;
  font-weight: 600;
}

.discussion-footer span {
  margin: 0 15px 0 0;
}
.discussion-footer span a {
  margin: 0 0px 0 2px;
}

.discussion-footer span.discussion-likes .active .fa-heart {
  color: black;
  font-size: 1rem;
}
.discussion-footer span.discussion-likes .active .fa-heart {
  color: red;
}

.nested-discussions {
  margin-left: 50px;
}

.center {
  margin-top: 50px;
}

.footer {
  min-height: 70px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

@media only screen and (max-width: 540px) {
  h1,
  .display-3 {
    font-size: 30px;
    margin: 0;
    padding: 0;
  }

  .mb-4 {
    margin: 0;
  }
}

